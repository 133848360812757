<template>
  <v-container>
        <v-row v-if="showHeaderImg" class="mb-10" justify="center">
            <v-col cols="12" sm="12" md="6" class="text-center">
                <v-img class="mx-auto" src="~@/assets/img/home_linea_familiar.png"></v-img>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="12" md="2"></v-col>
            <v-col cols="12" sm="12" md="8">
                <v-row no-gutters justify="center">
                    <v-col
                        v-for="(card, i) in itemsTreatments.treatments_items"
                        :key="i"
                        cols="12"
                        sm="6"
                        md="6"
                        class="text-center card-hover"
                    >
                        <v-card
                            max-width="250"
                            class="ma-6"
                            data-aos="flip-left"
                            flat
                            :data-aos-delay="500+(i*100)"
                            :loading="loading"
                            @click="setCurrentTratamiento(card)"
                        >
                            <v-img
                                :src="card.element_content"
                                class="white--text"
                                contain
                            >
                            </v-img>
                            <div>
                                <p class="text-h6 primary--text font-weight-bold text-center">{{ card.element_name }}</p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="2"></v-col>
        </v-row>

        <v-dialog
            v-model="showDialog"
            transition="dialog-bottom-transition"
            
            max-width="800"
        >
            <v-card
                flat
                :loading="loading"
                color="primary"
            >
                <v-toolbar 
                    color="primary"
                    dark
                    dense
                >
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-img 
                    contain
                    :src="currentTratamiento.image_url"
                ></v-img>
                <!-- <v-card-text>
                    <div class="text-h2 pa-12">Hello world!</div>
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiters
                    >
                        <v-carousel-item
                            v-for="(item,i) in currentTratamiento.carousel"
                            :key="i"
                            :src="item.src"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                            cycle
                        ></v-carousel-item>
                    </v-carousel>
                </v-card-text> -->
            </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import AOS from 'aos';

export default {
    props:["showHeaderImg"],
    data: () => ({
        currentTratamiento: {},
        showDialog: false,
    }),
    computed: {
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsTreatments"]),
    },
    mounted() {
        this.$nextTick(() => {
            AOS.refresh();
        });
    },
    methods: {
        setCurrentTratamiento(item) {
            this.showDialog = true;
            this.currentTratamiento = item;
        }
    }
}
</script>

<style>
.card-hover:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
</style>