<template>
  <div>
    <v-app>
      <navigation :color="color" :flat="flat" :textColor="textColor" :logoCompleto="logoCompleto" />
      <v-main>
        <v-carousel
          v-if="itemsTreatments.treatments_banner"
          hide-delimiters
          :show-arrows="false"
        >
          <v-carousel-item>
            <v-img :src="itemsTreatments.treatments_banner.url" />
          </v-carousel-item>
        </v-carousel>

        <v-row no-gutters class="" justify="center">
          <v-col cols="12" class="text-center">
            <v-img contain src="~@/assets/img/proc_header2.png"></v-img>
          </v-col>
        </v-row>

        <tratamientos></tratamientos>
      </v-main>
      <v-scale-transition>
        <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-scale-transition>
      <customFooter></customFooter>
    </v-app>
  </div>
</template>
  
<script>
import { mapState, mapActions } from "vuex";
import AOS from 'aos';
import navigation from "@/modules/landing/components/Navigation.vue";
import tratamientos from "@/modules/landing/components/Tratamientos.vue";
import customFooter from "@/modules/shared/layouts/Footer.vue";

import proc_header from '@/assets/img/proc_header.png';

export default {
  name: "Nosotros",
  components: {
    navigation,
    tratamientos,
    customFooter,
  },

  data: () => ({
    logoCompleto: null,
    fab: null,
    color: "primary",
    textColor: "white",
    flat: null,
    itemsCarousel: [
      {
        src: proc_header,
      },
    ],
  }),

  created() {
    this.getItemsTreatments();
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "primary";
      this.flat = true;
    }
  },
  computed: {
    ...mapState("loader", ["loading"]),
    ...mapState("landing", ["itemsTreatments"]),
  },
  mounted() {
    // Actualiza AOS después de que Vue ha renderizado el componente
    this.$nextTick(() => {
      AOS.refresh();
    });
  },
  methods: {
    ...mapActions({
      getItemsTreatments: "landing/getItemsTreatments",
    }),
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "dark";
        this.textColor = "white";
        this.flat = false;
        this.logoCompleto = false;
      } else {
        this.color = "primary";
        this.textColor = "white";
        this.flat = true;
        this.logoCompleto = true;
      }
    },
  }
};
</script>
  
<style scoped>
#app {
  background-image: url("~@/assets/img/home_background.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 10;
}

.custom-popup-class {
  background: transparent !important;
  /* Establecer el fondo transparente */
  box-shadow: none !important;
  /* Eliminar cualquier sombra */
  border: none !important;
  /* Eliminar cualquier borde */
  padding: 0 !important;
  /* Eliminar cualquier relleno */
}

.custom-content-class {
  padding: 0 !important;
  /* Eliminar cualquier relleno */
}
</style>